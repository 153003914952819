import { RouteProps, Route, Redirect } from 'react-router-dom'

export type ProtectedRouteProps = {
  isAuthenticated: boolean
  authenticationPath: string
} & RouteProps

const ProtectedRoute = ({
  isAuthenticated,
  authenticationPath,
  ...routeProps
}: ProtectedRouteProps) => {
  if (isAuthenticated) {
    return <Route {...routeProps} />
  } else {
    console.log('Redirect to', authenticationPath)
    return <Redirect to={{ pathname: authenticationPath }} />
  }
}

const ProtectedRouteWialon = ({
  isAuthenticated,
  authenticationPath,
  ...routeProps
}: ProtectedRouteProps) => {
  if (isAuthenticated) {
    return <Route {...routeProps} />
  } else {
    console.log('Redirect to', authenticationPath)
    return <Redirect to={{ pathname: authenticationPath }} />
  }
}

export {
    ProtectedRoute,
    ProtectedRouteWialon,
}
