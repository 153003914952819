import Header from '../../Components/Header'

import './Home.css'
import { useContext } from 'react'
import { AppContext } from '../../utils/store'
import { useEffect } from 'react'
import ConnectToWialon from '../../Components/ConnectToWialon'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Card from '../../Components/Card'
import { fetchApi } from '../../utils/auth'
import jwt, { JwtPayload } from 'jsonwebtoken'

interface HomeProp {}

const isTokenExpired = () => {
  const token = localStorage.getItem('JWT')

  if (token) {
    const decodedToken = jwt.decode(token) as JwtPayload

    return decodedToken.exp && decodedToken.exp * 1000 < Date.now()
  }

  return false
}

const Home = (_prop: HomeProp) => {
  const { state, dispatch } = useContext(AppContext)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (isTokenExpired()) {
      dispatch({ type: 'logout' })
    }

    fetchApi('/user')
      .then((res) => res.json())
      .then((json) => {
        dispatch({ type: 'set_user', payload: json })
      })
      .catch((err) => toast.error(err))
  }, [dispatch])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const wialonToken = queryParams.get('access_token')

    if (
      state.user !== undefined &&
      state.user?.wialon_token === undefined &&
      wialonToken !== null
    ) {
      dispatch({ type: 'connect_wialon', payload: wialonToken })

      fetchApi('/wialon', { method: 'POST', body: JSON.stringify({ token: wialonToken }) })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            toast.error(json.error)
          }

          queryParams.delete('access_token')
          history.replace({
            search: queryParams.toString(),
          })
        })
        .catch((err) => toast.error(err))
    }
  }, [dispatch, history, location.search, state.user])

  if (!state.user) {
    // TODO(Afourcat): Replace by a proper loading.
    return (
      <Card>
        <h1>Loading</h1>
      </Card>
    )
  }

  if (state.user?.wialon_token !== undefined) {
    history.push('/occupation');
  }

  return (
    <>
      <Header />
      <div className="Page">
        <ConnectToWialon />
      </div>
    </>
  )
}

export default Home
