const isAuthenticated = (): boolean => localStorage.getItem('JWT') !== null

const fetchApi = async (url: string, init?: RequestInit, auth: boolean = true) => {
  const baseUrl =
    process.env.REACT_APP_API_ENDPOINT || 'https://jdlyxhz878.execute-api.eu-west-1.amazonaws.com/test'

  let headers = init?.headers

  if (auth) {
    headers = {
      ...init?.headers,
      Authorization: 'Bearer ' + localStorage.getItem('JWT'),
    }
  }

  return fetch(`${baseUrl}${url}`, {
    ...init,
    headers: headers,
  })
}

const fetchWialonApi = async (body: URLSearchParams) => {
  return fetchApi("/wialon/proxy", {
    method: "POST",
    body: body.toString(),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }, true)
}

export { isAuthenticated, fetchApi, fetchWialonApi }