import Select, { ActionMeta, OptionsType } from 'react-select'
import './SelectField.css'

interface SelectFieldProp<T> {
  label: string
  options?: T[]
  onChange?:
    | (((value: OptionsType<T>, actionMeta: ActionMeta<T>) => void) &
        ((value: OptionsType<T>, action: ActionMeta<T>) => void))
    | undefined
  errorMsg?: string
  value: T[]
}

const SelectField = <T,>(prop: SelectFieldProp<T>) => {
  return (
    <div className="SelectField">
      <label>{prop.label}</label>
      <Select isMulti options={prop.options} defaultValue={prop.value} onChange={prop.onChange} />
      {prop.errorMsg !== undefined && <p>{prop.errorMsg}</p>}
    </div>
  )
}

export default SelectField
