import React, { useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { ProtectedRouteWialon, ProtectedRoute } from './Components/ProtectedRoute'
import Login from './Views/Login/Login'
import Validate from './Views/Validate/Validate'
import Home from './Views/Home/Home'
import ResetPassword from './Views/ResetPassword/ResetPassword'
import ChangePassword from './Views/ChangePassword/ChangePassword'

import './App.css'

import { AppContext } from './utils/store'
import { ToastContainer } from 'react-toastify'
import ForgotPassword from './Views/ForgottenPassword/ForgotPassword'
import EcoDrive from './Components/EcoDrive'
import Occupation from './Components/Occupation'

function App() {
  const { state } = useContext(AppContext)

  return (
    <Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <ProtectedRoute
          {...{ isAuthenticated: state.isAuthenticated, authenticationPath: '/login' }}
          exact={true}
          path="/"
          component={Home}
        />
        <ProtectedRoute
          {...{ isAuthenticated: state.isAuthenticated, authenticationPath: '/login' }}
          exact={true}
          path="/change-password"
          component={ChangePassword}
        />
        <ProtectedRouteWialon
          {...{ isAuthenticated: state.isAuthenticated && state.user?.wialon_token !== undefined, authenticationPath: '/' }}
          exact={true}
          path="/occupation"
          component={Occupation}
        />
        <ProtectedRouteWialon
          {...{ isAuthenticated: state.isAuthenticated && state.user?.wialon_token !== undefined, authenticationPath: '/' }}
          exact={true}
          path="/eco-fleet"
          component={EcoDrive}
        />
        {!state.isAuthenticated && (
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
        )}
        {!state.isAuthenticated && (
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
        )}
        {!state.isAuthenticated && (
          <Route path="/login">
            <Login />
          </Route>
        )}
        {!state.isAuthenticated && (
          <Route path="/validate">
            <Validate />
          </Route>
        )}
        <Route path="*">
          {state.isAuthenticated ? <Redirect to="/" /> : <Redirect to="/login" />}
        </Route>
      </Switch>
    </Router>
  )
}

export default App
